import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: '',
    email: '',
    firstName: '',
    lastName: '',
    role: 'user',
    eventBookmarks: [],
    jobPostBookmarks: []
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserProfile: (state, action) => {
            return { ...state, ...action.payload };
        },
        addEventBookmark: (state, action) => {
            if (!state.eventBookmarks.includes(action.payload)) {
                state.eventBookmarks.push(action.payload);
            }
        },
        removeEventBookmark: (state, action) => {
            // Remove event ID from bookmarks
            state.eventBookmarks = state.eventBookmarks.filter((eventId) => eventId !== action.payload);
        },
        addJobPostBookmark: (state, action) => {
            if (!state.jobPostBookmarks.includes(action.payload)) {
                state.jobPostBookmarks.push(action.payload);
            }
        },
        removeJobPostBookmark: (state, action) => {
            // Remove event ID from bookmarks
            state.jobPostBookmarks = state.jobPostBookmarks.filter((eventId) => eventId !== action.payload);
        },
        resetUserState: () => initialState
    }
});

export const { setUserProfile, addEventBookmark, removeEventBookmark, addJobPostBookmark, removeJobPostBookmark, resetUserState } = userSlice.actions;
export default userSlice.reducer;
