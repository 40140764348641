export const ONBOARDING_STEPPER_STEPS = ['Personal details', 'Professional details', 'Verification'];

export const EMPLOYMENT_STATUS = {
    CURRENTLY_EMPLOYED: 'CURRENTLY_EMPLOYED',
    ACTIVELY_LOOKING_FOR_JOB: 'ACTIVELY_LOOKING_FOR_JOB',
    EMPLOYED_AND_SEEKING_JOB: 'EMPLOYED_AND_SEEKING_JOB'
};

export const EMPLOYMENT_STATUS_OPTIONS = [
    { value: 'CURRENTLY_EMPLOYED', label: 'Currently employed' },
    { value: 'ACTIVELY_LOOKING_FOR_JOB', label: 'Actively looking for jobs' },
    { value: 'EMPLOYED_AND_SEEKING_JOB', label: 'Employed & seeking job' }
];

export const EMPLOYMENT_TYPE = {
    FULL_TIME: 'FULL_TIME',
    PART_TIME: 'PART_TIME',
    INTERNSHIP: 'INTERNSHIP'
};
// THESIS: 'THESIS'

export const EMPLOYMENT_TYPE_OPTIONS = [
    { value: 'FULL_TIME', label: 'Full time' },
    { value: 'PART_TIME', label: 'Part time' },
    { value: 'INTERNSHIP', label: 'Internship' }
];
// { value: 'THESIS', label: 'Thesis' }


export const LOADING_STATUS = {
    INITIAL: 'initial',
    LOADING: 'loading',
    LOADED: 'loaded',
    FAILED: 'failed'
};

export const SAVING_STATUS = {
    INITIAL: 'initial',
    SENDING: 'sending',
    SAVED: 'saved',
    FAILED: 'failed'
};

export const POST_STATUS = {
    INITIAL: 'INITIAL',
    POSTING: 'POSTING',
    SAVED: 'SAVED',
    FAILED: 'FAILED'
};

export const MESSAGE_STATUS = {
    INITIAL: 'INITIAL',
    POSTING: 'POSTING',
    SAVED: 'SAVED',
    FAILED: 'FAILED'
};

export const DELETE_STATUS = {
    INITIAL: 'INITIAL',
    DELETING: 'DELETING',
    DELETED: 'DELETED',
    FAILED: 'FAILED'
};

export const GET_STATUS = {
    INITIAL: 'INITIAL',
    FETCHING: 'FETCHING',
    FETCHED: 'FETCHED',
    FAILED: 'FAILED'
};

export const JOB_TYPE_OPTIONS = [
    { value: 'FULL_TIME', label: 'Full time' },
    { value: 'PART_TIME', label: 'Part time' },
    { value: 'INTERNSHIP', label: 'Internship' }
];

export const JOB_TYPE = {
    FULL_TIME: 'FULL_TIME',
    PART_TIME: 'PART_TIME',
    INTERNSHIP: 'INTERNSHIP'
};

export const JOB_POST_STATUS = {
    INIT: 'INIT',
    IN_REVIEW: 'IN_REVIEW',
    ACTIVE: 'ACTIVE',
    REJECTED: 'REJECTED',
    CLOSED: 'CLOSED'
};

export const EVENT_TYPE = {
    PAID: 'PAID',
    FREE: 'FREE'
};

export const EVENT_MODE = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE'
};

export const EVENT_STATUS = {
    IN_REVIEW: 'IN_REVIEW',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    ON_HOLD: 'ON_HOLD',
    DELETED: 'DELETED'
};

export const HP_POST_STATUS = {
    INIT: 'INIT',
    IN_REVIEW: 'IN_REVIEW',
    ACTIVE: 'ACTIVE',
    REJECTED: 'REJECTED',
    CLOSED: 'CLOSED'
};

export const USER_PROFILE_STATUS = {
    IN_REVIEW: 'IN_REVIEW',
    ACTIVE: 'ACTIVE',
    REJECTED: 'REJECTED',
    ON_HOLD: 'ON_HOLD',
    DELETED: 'DELETED'
};

export const MESSAGE_CATEGORY = {
    GENERAL: 'GENERAL',
    EVENT: 'EVENT',
    JOB: 'JOB'
};



export const SALARY_STATUS = {
    SALARY_50K_TO_75K: '50K_TO_75K',
    SALARY_75K_TO_100K: '75K_TO_100K',
    SALARY_100K_TO_125K: '100K_TO_125K',
    SALARY_125K_TO_150K: '125K_TO_150K',
    SALARY_150K_PLUS: '150K+',

};

export const SALARY_RANGE_OPTIONS = [
    { value: '50K_TO_75K', label: '50K to 75K' },
    { value: '75K_TO_100K', label: '75K to 100K' },
    { value: '100K_TO_125K', label: '100K to 125K' },
    { value: '125K_TO_150K', label: '125K to 150K' },
    { value: '150K+', label: '150K +' }
];