import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

//pathname is url in browser and href is link which will open on clicking perticular sidebar item as defined in Layout/config.js
const isActive = (pathname, href) => {
    const exactMatchPaths = ['/admin/manage-requests', '/admin/people-management', '/admin/monitoring-job-posts', '/admin/events', '/about-us', '/faqs', '/messages', '/reset-password', '/privacy-policy', '/terms-and-conditions', '/feedback', '/promotions', '/testimonial'];

    // Check for exact match paths
    if (exactMatchPaths.includes(href)) {
        return href === pathname;
    }

    // Define starting path matches for cleaner code
    const startsWithPaths = {
        '/': ['/post'],
        '/jobs': ['/jobs'],
        '/events': ['/events'],
        '/mentoring': ['/mentoring'],
        '/profile': ['/profile'],
        '/bookmarks': ['/bookmarks'],
        '/my-posts': ['/my-posts']
    };

    const uuidRegex = /^[0-9a-fA-F-]{36}$/;

    // Check for starting path matches
    if (startsWithPaths[href]) {
        return (
            href === pathname ||
            startsWithPaths[href].some((start) => {
                // For '/profile', ensure we don't match paths like '/profile/:userId'
                if (start === '/profile' && pathname.startsWith('/profile/')) {
                    const subPath = pathname.split('/')[2];
                    if (uuidRegex.test(subPath)) {
                        return false; // Exclude paths like '/profile/:userId' where :userId is a UUID
                    }
                }
                return pathname.startsWith(start);
            })
        );
    }

    return false;
};

export function NavItem({ disabled, external, href, pathname, title, icon, onClick }) {
    const active = isActive(pathname, href);
    const Icon = icon;

    return (
        <li>
            <StyledNavBox
                as={href ? (external ? 'a' : Link) : 'button'}
                to={href && !external ? href : undefined}
                href={href && external ? href : undefined}
                target={external ? '_blank' : undefined}
                rel={external ? 'noreferrer' : undefined}
                role={!href ? 'button' : undefined}
                disabled={disabled}
                active={active}
                onClick={onClick}
            >
                <IconContainer>{Icon ? <Icon fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'} fontSize="var(--icon-fontSize-md)" weight={active ? 'fill' : undefined} /> : null}</IconContainer>
                <TitleContainer>
                    <StyledNavItemTitle active={active}>{title}</StyledNavItemTitle>
                </TitleContainer>
            </StyledNavBox>
        </li>
    );
}

const StyledNavItemTitle = styled(Typography)(({ theme, active }) => ({
    color: active ? '#ffffff' : theme.palette.text.neutral[700],
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '28px'
}));

const StyledNavBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'active' && prop !== 'as'
})(({ disabled, active }) => ({
    alignItems: 'center',
    borderRadius: '8px',
    color: 'var(--NavItem-color)',
    cursor: 'pointer',
    display: 'flex',
    flex: '0 0 auto',
    gap: '8px',
    padding: '8px 16px',
    position: 'relative',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    ...(disabled && {
        backgroundColor: 'var(--NavItem-disabled-background)',
        color: 'var(--NavItem-disabled-color)',
        cursor: 'not-allowed'
    }),
    ...(active && {
        backgroundColor: 'var(--NavItem-active-background)',
        color: 'var(--NavItem-active-color)'
    })
}));

const IconContainer = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flex: '0 0 auto'
});

const TitleContainer = styled(Box)({
    flex: '1 1 auto'
});
