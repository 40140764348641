import { createSlice } from '@reduxjs/toolkit';
import { JOB_POST_STATUS } from '../../constants/constants';

const initialState = {
    company_name: '',
    position: '',
    job_type: '',
    region: '',
    job_link: '',
    description: '',
    salary_range: [10000, 200000],
    job_post_status: JOB_POST_STATUS.INIT
};

const jobPostSlice = createSlice({
    name: 'jobPost',
    initialState,
    reducers: {
        setJobPostData: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetJobPostState: () => initialState
    }
});

export const { setJobPostData, resetJobPostState } = jobPostSlice.actions;
export default jobPostSlice.reducer;
