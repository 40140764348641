import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const StyledSectionTitle = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 24px;
    font-weight: 500;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 20px;
    }
`;

const SectionTitle = ({ children, ...props }) => {
    return <StyledSectionTitle {...props}>{children}</StyledSectionTitle>;
};

export default SectionTitle;
