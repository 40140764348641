import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormBtn from '../Common/FormBtn';
import FormInput from '../Common/FormInput';
import InverseBtn from '../Common/InverseBtn';
import { Label } from '../Common/Label';
import { RequiredMark } from '../Common/RequiredMark';
import SectionTitle from '../Common/SectionTitle';
import StyledHelperText from '../Common/StyledHelperText';
import styled from '@emotion/styled';
import { addEducation, resetEducation } from '../../redux/Slices/userProfileSlice';

export const EducationDialog = ({ openDialog, handleClose }) => {
    const dispatch = useDispatch();
    const { education } = useSelector((state) => state.userProfile);

    const [localEducation, setLocalEducation] = useState(education);

    const {
        control,
        handleSubmit,
        trigger,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: localEducation
    });

    const onSubmit = () => {
        dispatch(addEducation(localEducation));
        handleClose();
    };

    const handleResetEducation = () => {
        const resetValues = {
            institute_name: '',
            course: '',
            from: '',
            to: ''
        };

        setLocalEducation(resetValues);
        reset(resetValues);
        dispatch(resetEducation());
    };

    const handleInputChange = async (name, value) => {
        setLocalEducation((prev) => ({
            ...prev,
            [name]: value
        }));
        await trigger(name);
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <CardContainer component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    <Header>
                        <SectionTitle>Education Details</SectionTitle>
                        <CloseOutlinedIconStyled onClick={handleClose} />
                    </Header>
                </DialogTitle>
                <DialogContent>
                    <Box mt={1}>
                        <Controller
                            name="institute_name"
                            control={control}
                            rules={{
                                required: 'Institute name is required.',
                                minLength: {
                                    value: 3,
                                    message: 'Institute name must be between 3-75 characters long'
                                },
                                maxLength: {
                                    value: 75,
                                    message: 'Institute name must be between 3-75 characters long'
                                },
                                validate: (value) => value.trim().length >= 3 || 'Institute name must be between 3-75 characters long'
                            }}
                            render={({ field }) => (
                                <FormInput
                                    label="Institute name"
                                    id="institute"
                                    type="text"
                                    value={localEducation.institute_name}
                                    placeholder="e.g., Harvard University, Stanford"
                                    required
                                    {...field}
                                    error={!!errors.institute_name}
                                    helperText={errors.institute_name ? errors.institute_name.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        handleInputChange('institute_name', e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <Controller
                            name="course"
                            control={control}
                            rules={{
                                required: 'Course is required.',
                                minLength: {
                                    value: 3,
                                    message: 'Course must be between 3-75 characters long'
                                },
                                maxLength: {
                                    value: 75,
                                    message: 'Course must be between 3-75 characters long'
                                },
                                validate: (value) => value.trim().length >= 3 || 'Course must be between 3-75 characters long'
                            }}
                            render={({ field }) => (
                                <FormInput
                                    label="Course"
                                    id="course"
                                    type="text"
                                    value={localEducation.course}
                                    placeholder="e.g., Computer Science, MBA"
                                    required
                                    {...field}
                                    error={!!errors.course}
                                    helperText={errors.course ? errors.course.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        handleInputChange('course', e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="from"
                                    control={control}
                                    rules={{
                                        required: 'Start date is required.'
                                    }}
                                    render={({ field: { onChange, ref } }) => (
                                        <PickerContainer>
                                            <Label error={errors.from}>
                                                <RequiredMark /> Start date
                                            </Label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <StyledDateTimePicker
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                    value={localEducation.from ? dayjs(localEducation.from) : null}
                                                    onChange={(newValue) => {
                                                        onChange(newValue);
                                                        handleInputChange('from', newValue);
                                                    }}
                                                    maxDate={dayjs()}
                                                    renderInput={(props) => <TextField {...props} fullWidth error={!!errors.from} helperText={errors.from ? errors.from.message : ''} />}
                                                    inputRef={ref}
                                                />
                                            </LocalizationProvider>
                                            <StyledHelperText error={errors.from}>{errors.from?.message}</StyledHelperText>
                                        </PickerContainer>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{
                                        validate: (toDate) => {
                                            if (!toDate) return true;
                                            const fromDate = localEducation.from ? dayjs(localEducation.from) : null;
                                            return fromDate && dayjs(toDate).isAfter(fromDate) ? true : 'End date must be after start date';
                                        }
                                    }}
                                    render={({ field: { onChange, ref } }) => (
                                        <PickerContainer>
                                            <Label error={errors.to}>End date ( Leave blank if still studying )</Label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <StyledDateTimePicker
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                    value={localEducation.to ? dayjs(localEducation.to) : null}
                                                    onChange={(newValue) => {
                                                        onChange(newValue);
                                                        handleInputChange('to', newValue);
                                                    }}
                                                    maxDate={dayjs()}
                                                    renderInput={(props) => <TextField {...props} fullWidth error={!!errors.to} helperText={errors.to ? errors.to.message : ''} />}
                                                    inputRef={ref}
                                                />
                                            </LocalizationProvider>
                                            <StyledHelperText error={errors.to}>{errors.to?.message}</StyledHelperText>
                                        </PickerContainer>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActionsBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <InverseBtn sx={{ width: '100%', padding: '12px' }} onClick={handleResetEducation}>
                                Reset
                            </InverseBtn>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormBtn type="submit" sx={{ mb: 2 }}>
                                Save
                            </FormBtn>
                        </Grid>
                    </Grid>
                </DialogActionsBox>
            </CardContainer>
        </Dialog>
    );
};

// Reuse styled components from WorkExperienceDialog
const CardContainer = styled(Box)`
    background: white;
    border-radius: 8px;
`;

const Header = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const CloseOutlinedIconStyled = styled(CloseOutlinedIcon)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    padding: 8px;
    cursor: pointer;
`;

const DialogActionsBox = styled(DialogActions)`
    margin: 0px 16px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin: 0px 16px 16px 16px;
    }
`;

const StyledDateTimePicker = styled(DatePicker)({
    '& .MuiOutlinedInput-root': {
        fontSize: '14px', // Reduce font size
        width: '100%'
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '14px', // Reduce font size
        padding: '12px 10px' // Adjust padding
    },
    '& .MuiInputAdornment-root': {
        margin: '8px' // Adjust icon margin if needed
    }
});

const PickerContainer = styled(Box)({
    marginTop: '8px'
});
