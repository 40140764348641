import styled from '@emotion/styled';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addWorkExperience, resetWorkExperience } from '../../redux/Slices/userProfileSlice';
import FormBtn from '../Common/FormBtn';
import FormInput from '../Common/FormInput';
import InverseBtn from '../Common/InverseBtn';
import { Label } from '../Common/Label';
import { RequiredMark } from '../Common/RequiredMark';
import SectionTitle from '../Common/SectionTitle';
import StyledHelperText from '../Common/StyledHelperText';

export const WorkExperienceDialog = ({ openDialog, handleClose }) => {
    const dispatch = useDispatch();
    const { work_experience } = useSelector((state) => state.userProfile);

    // Local state for form values, initialized from Redux state
    const [localWorkExperience, setLocalWorkExperience] = useState(work_experience);

    const {
        control,
        handleSubmit,
        trigger,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: localWorkExperience
    });

    // Function to handle form submission and update Redux
    const onSubmit = () => {
        dispatch(addWorkExperience(localWorkExperience)); // Dispatch local state to Redux
        handleClose(); // Close dialog
    };

    const handleResetWorkExperience = () => {
        const resetValues = {
            company_name: '',
            role: '',
            from: '',
            to: ''
        };

        // Reset the local state
        setLocalWorkExperience(resetValues);

        // Reset the form using react-hook-form's reset method
        reset(resetValues);

        // Reset Redux state
        dispatch(resetWorkExperience());
    };

    // Function to handle input change and update local state
    const handleInputChange = async (name, value) => {
        setLocalWorkExperience((prev) => ({
            ...prev,
            [name]: value
        }));
        await trigger(name); // Validate the field after the change
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <CardContainer component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    <Header>
                        <SectionTitle>Recent work experience</SectionTitle>
                        <CloseOutlinedIconStyled onClick={handleClose} />
                    </Header>
                </DialogTitle>
                <DialogContent>
                    <Box mt={1}>
                        <Controller
                            name="company_name"
                            control={control}
                            rules={{
                                required: 'Company name is required.',
                                minLength: {
                                    value: 3,
                                    message: 'Company name must be between 3-75 characters long'
                                },
                                maxLength: {
                                    value: 75,
                                    message: 'Company name must be between 3-75 characters long'
                                },
                                validate: (value) => value.trim().length >= 3 || 'Company name must be between 3-75 characters long'
                            }}
                            render={({ field }) => (
                                <FormInput
                                    label="Company name"
                                    id="company"
                                    type="text"
                                    value={localWorkExperience.company_name} // Local state used here
                                    placeholder="e.g., Amazon, Meta"
                                    required
                                    {...field}
                                    error={!!errors.company_name}
                                    helperText={errors.company_name ? errors.company_name.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        handleInputChange('company_name', e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <Controller
                            name="role"
                            control={control}
                            rules={{
                                required: 'Role is required.',
                                minLength: {
                                    value: 3,
                                    message: 'Role must be between 3-75 characters long'
                                },
                                maxLength: {
                                    value: 75,
                                    message: 'Role must be between 3-75 characters long'
                                },
                                validate: (value) => value.trim().length >= 3 || 'Role must be between 3-75 characters long'
                            }}
                            render={({ field }) => (
                                <FormInput
                                    label="Role"
                                    id="role"
                                    type="text"
                                    value={localWorkExperience.role} // Local state used here
                                    placeholder="Software Engineer"
                                    required
                                    {...field}
                                    error={!!errors.role}
                                    helperText={errors.role ? errors.role.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        handleInputChange('role', e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <Grid container spacing={2}>
                            {/* Start Date Picker */}
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="from"
                                    control={control}
                                    rules={{
                                        required: 'Start date required.'
                                    }}
                                    render={({ field: { onChange, ref } }) => (
                                        <PickerContainer>
                                            <Label error={errors.from}>
                                                <RequiredMark /> Start date
                                            </Label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <StyledDateTimePicker
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                    value={localWorkExperience.from ? dayjs(localWorkExperience.from) : null} // Local state used here
                                                    onChange={(newValue) => {
                                                        onChange(newValue);
                                                        handleInputChange('from', newValue);
                                                    }}
                                                    maxDate={dayjs()}
                                                    renderInput={(props) => <TextField {...props} fullWidth error={!!errors.from} helperText={errors.from ? errors.from.message : ''} />}
                                                    inputRef={ref}
                                                />
                                            </LocalizationProvider>
                                            <StyledHelperText error={errors.from}>{errors.from?.message}</StyledHelperText>
                                        </PickerContainer>
                                    )}
                                />
                            </Grid>

                            {/* End Date Picker */}
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{
                                        validate: (toDate) => {
                                            if (!toDate) return true;
                                            const fromDate = localWorkExperience.from ? dayjs(localWorkExperience.from) : null;
                                            return fromDate && dayjs(toDate).isAfter(fromDate) ? true : 'End date must be greater than start date';
                                        }
                                    }}
                                    render={({ field: { onChange, ref } }) => (
                                        <PickerContainer>
                                            <Label error={errors.to}>End date ( keep blank if still working )</Label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <StyledDateTimePicker
                                                    slotProps={{ textField: { fullWidth: true } }}
                                                    value={localWorkExperience.to ? dayjs(localWorkExperience.to) : null} // Local state used here
                                                    onChange={(newValue) => {
                                                        onChange(newValue);
                                                        handleInputChange('to', newValue);
                                                    }}
                                                    maxDate={dayjs()}
                                                    renderInput={(props) => <TextField {...props} fullWidth error={!!errors.to} helperText={errors.to ? errors.to.message : ''} />}
                                                    inputRef={ref}
                                                />
                                            </LocalizationProvider>
                                            <StyledHelperText error={errors.to}>{errors.to?.message}</StyledHelperText>
                                        </PickerContainer>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActionsBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <InverseBtn sx={{ width: '100%', padding: '12px' }} onClick={handleResetWorkExperience}>
                                Reset
                            </InverseBtn>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormBtn type="submit" sx={{ mb: 2 }}>
                                Save
                            </FormBtn>
                        </Grid>
                    </Grid>
                </DialogActionsBox>
            </CardContainer>
        </Dialog>
    );
};

// Styled Components using Emotion
const CardContainer = styled(Box)`
    background: white;
    border-radius: 8px;
`;

const Header = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const CloseOutlinedIconStyled = styled(CloseOutlinedIcon)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    padding: 8px;
    cursor: pointer;
`;

const DialogActionsBox = styled(DialogActions)`
    margin: 0px 16px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin: 0px 16px 16px 16px;
    }
`;

const StyledDateTimePicker = styled(DatePicker)({
    '& .MuiOutlinedInput-root': {
        fontSize: '14px', // Reduce font size
        width: '100%'
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '14px', // Reduce font size
        padding: '12px 10px' // Adjust padding
    },
    '& .MuiInputAdornment-root': {
        margin: '8px' // Adjust icon margin if needed
    }
});

const PickerContainer = styled(Box)({
    marginTop: '8px'
});
