import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    postActionId: '',
    jobPostActionId: '',
    eventActionId: '',
    profileRequestActionId: '',
    jobBookmarkActionId: ''
};
const eventListenerSlice = createSlice({
    name: 'eventListener',
    initialState,
    reducers: {
        setActionData: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetEventListenerState: () => initialState
    }
});

export const { setActionData, resetEventListenerState } = eventListenerSlice.actions;
export default eventListenerSlice.reducer;
