import { createSlice } from '@reduxjs/toolkit';
import { EVENT_MODE, EVENT_STATUS, EVENT_TYPE } from '../../constants/constants';

const initialState = {
    event_name: '',
    event_type: EVENT_TYPE.FREE,
    event_mode: EVENT_MODE.OFFLINE,
    location: '',
    event_link: '',
    description: '',
    event_status: EVENT_STATUS.INIT,
    event_start_datetime: '',
    event_end_datetime: '',
    created_by: ''
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventData: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetEventState: () => initialState
    }
});

export const { setEventData, resetEventState } = eventSlice.actions;
export default eventSlice.reducer;
