import {  useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Alert, Box, Button, Container, FormControlLabel, Grid, Radio, RadioGroup, Snackbar, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { GetCity, GetCountries, GetState } from 'react-country-state-city';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateUserProfile } from '../../api/User/user';
import { ComingSoonDialog } from '../../component/Common/ComingSoonDialog';
import CSCSelectInput from '../../component/Common/CSCSelectInput';
import FormBtn from '../../component/Common/FormBtn';
import FormInput from '../../component/Common/FormInput';
import { Label } from '../../component/Common/Label';
import { RequiredMark } from '../../component/Common/RequiredMark';
import SelectInput from '../../component/Common/SelectInput';
import SlideUp from '../../component/Common/SlideUp';
import StyledHelperText from '../../component/Common/StyledHelperText';
import StyledTextArea from '../../component/Common/StyledTextArea';
import TagContainer from '../../component/Common/TagContainer';
import { WorkExperienceDialog } from '../../component/Profile/WorkExperienceDialog';
import { EMPLOYMENT_STATUS, EMPLOYMENT_STATUS_OPTIONS, EMPLOYMENT_TYPE_OPTIONS, POST_STATUS, SALARY_RANGE_OPTIONS } from '../../constants/constants';
import { setActionData } from '../../redux/Slices/eventListnerSlice';
import { resetUserProfileState, setUserProfileData } from '../../redux/Slices/userProfileSlice';
import { formatInterval, isNullOrEmpty, isValidEducation, isValidWorkExperience, truncateText, waitForSeconds } from '../../utils/helpers';
import { EditOutlined as EditIcon } from '@mui/icons-material'; // Assuming you are using Material-UI for icons
import { IconButton } from '@mui/material'; // For the edit button
import { EducationDialog } from '../../component/Profile/EducationDialog';
import AddButton from '../../component/Common/AddButton';
import AvatarPlaceholder from '../../component/Common/AvatarPlaceholder';

const EditProfile = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = location?.state?.userProfile;
    const formData = useSelector((state) => state.userProfile);
    const { firstName, lastName } = useSelector((state) => state.user);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [slideUpOpen, setSlideUpOpen] = useState(false);
    const [openWorkExperieceDialog, setOpenWorkExperieceDialog] = useState(false);
    const [openEducationDialog, setOpenEducationDialog] = useState(false);
    const [slideUpContent, setSlideUpContent] = useState('');
    const [profileEditError, setProfileEditError] = useState('');
    const [profileEditStatus, setProfileEditStatus] = useState();
    const [openComingSoonDialog, setOpenComingSoonDialog] = useState(false);
    const eventListenerData = useSelector((state) => state.eventListener);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const textLimit = isMobile ? 20 : 35;

    const {
        control,
        handleSubmit,
        trigger,
        formState: { errors }
    } = useForm({
        defaultValues: formData
    });

    const hasErrors = Object.keys(errors).length > 0; // If there are errors, `errors` will have keys

    const handleInputChange = async (name, value) => {
        dispatch(
            setUserProfileData({
                ...formData,
                [name]: value
            })
        );
        await trigger(name);
    };

    const fetchCountries = () => {
        GetCountries()
            .then((result) => {
                const filteredCountries = result.filter((country) => country.region === 'Europe');
                setCountries(filteredCountries);
            })
            .catch((error) => {
                console.error('Error fetching countries:', error);
            });
    };

    const fetchStates = (countryId) => {
        GetState(countryId)
            .then((result) => {
                setStates(result);
            })
            .catch((error) => {
                console.error('Error fetching states:', error);
            });
    };

    const fetchCities = (countryId, stateId) => {
        GetCity(countryId, stateId)
            .then((result) => {
                setCities(result);
            })
            .catch((error) => {
                console.error('Error fetching cities:', error);
            });
    };

    const handleCountryChange = async (e) => {
        const countryId = e.target.value;

        // Use the `find` method instead of `forEach`
        const selectedCountry = countries.find((country) => country.id === countryId);

        // If a country is found, proceed with the update
        const countryName = selectedCountry ? selectedCountry.name : '';
        // Dispatch to Redux with the updated country details
        dispatch(
            setUserProfileData({
                ...formData,
                country: countryName,
                country_id: countryId,
                state: '',
                city: ''
            })
        );

        // Fetch states and reset states and cities
        fetchStates(countryId);
        setStates([]);
        setCities([]);
    };

    const handleStateChange = (e) => {
        const stateId = e.target.value;

        // Use the `find` method to get the selected state
        const selectedState = states.find((state) => state.id === stateId);

        // Get the state name or default to an empty string if not found
        const stateName = selectedState ? selectedState.name : '';

        // Dispatch to Redux with updated state details
        dispatch(
            setUserProfileData({
                ...formData,
                state: stateName,
                state_id: stateId,
                city: '' // Reset city when state changes
            })
        );

        // Fetch cities based on the selected state
        fetchCities(formData.country_id, stateId);
    };

    const handleCityChange = (e) => {
        const cityId = e.target.value;

        const selectedCity = cities.find((city) => city.id === cityId);

        const cityName = selectedCity ? selectedCity.name : '';

        dispatch(
            setUserProfileData({
                ...formData,
                city: cityName,
                city_id: cityId
            })
        );
    };

    const handleSlideUpOpen = (content) => {
        setSlideUpContent(content);
        setSlideUpOpen(true);
    };

    const handleSlideUpClose = () => {
        setSlideUpOpen(false);
        setSlideUpContent('');
    };

    const handleOpenWorkExperienceDialog = () => {
        setOpenWorkExperieceDialog(true);
    };

    const handleCloseWorkExperienceDialog = () => {
        setOpenWorkExperieceDialog(false);
    };

    const handleOpenEducationDialog = () => {
        setOpenEducationDialog(true);
    };

    const handleCloseEducationDialog = () => {
        setOpenEducationDialog(false);
    };

    const handleEdit = (content) => {
        handleSlideUpOpen(content);
    };

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // Function to open Snackbar with custom message and severity
    const handleOpenSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };
    // Function to close Snackbar
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const onSubmit = (data) => {
        setProfileEditError('');
        const profileStateData = { ...formData };

        if (profileStateData.employment_status === EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED) {
            // Reset preferred_position when currently employed
            profileStateData.preferred_position = '';
            //no expected salary
            profileStateData.expected_salary = '';
        }
        if (profileStateData.employment_status === EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB) {
            // Reset current_job_title and current_company when actively looking for a job
            profileStateData.current_job_title = '';
            profileStateData.current_company = '';
            //no current salary
            profileStateData.current_salary = '';
        }
        delete profileStateData.salary_range;

        setProfileEditStatus(POST_STATUS.POSTING);

        updateUserProfile(userProfile?.user_id, profileStateData)
            .then((response) => {
                if (response.error) {
                    setProfileEditStatus(POST_STATUS.FAILED);
                    handleOpenSnackbar(response.message, 'error');
                    return waitForSeconds(1);
                } else {
                    setProfileEditStatus(POST_STATUS.SAVED);
                    dispatch(resetUserProfileState());
                    dispatch(
                        setActionData({
                            ...eventListenerData,
                            eventActionId: `profile_edit_${userProfile?.user_id}_${dayjs()}`
                        })
                    );
                    handleOpenSnackbar('Profile updated successfully!', 'success');
                    return waitForSeconds(1);
                }
            })
            .then(() => {
                navigate('/profile', { replace: true });
                window.location.reload();
            });
    };

    const handleOpenComingSoonDialog = () => {
        setOpenComingSoonDialog(true);
    };
    const handleCloseComingSoonDialog = () => {
        setOpenComingSoonDialog(false);
    };

    useEffect(() => {
        if (!countries.length) {
            fetchCountries();
        }
    }, []);
    useEffect(() => {
        if (formData.country_id) {
            fetchStates(formData.country_id);
        }
    }, [formData.country_id]);

    useEffect(() => {
        if (formData.country_id && formData.state_id) {
            fetchCities(formData.country_id, formData.state_id);
        }
    }, [formData.state_id]);

    useEffect(() => {
        if (formData.state_id && formData.city_id) {
            fetchCities(formData.country_id, formData.state_id);
        }
    }, []);

    return (
        <Container maxWidth="xl" sx={{ py: 3 }}>
            <Box sx={{ mt: 2 }}>
                <StyledWelcomeText>{`Welcome ${firstName} ${lastName}`}</StyledWelcomeText>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#52525B',
                        marginTop: '4px'
                    }}
                >
                    Manage your personal details with ease in this section
                </Typography>
            </Box>
            <UserContainer>
                <AvatarContainer>
                    <AvatarPlaceholder firstName={firstName} lastName={lastName} size="64px" fontSize="24px" />
                </AvatarContainer>
                <UserDetails>
                    <FormBtn sx={{ padding: '8px 0px' }} onClick={handleOpenComingSoonDialog}>
                        Upload new photo
                    </FormBtn>
                    <UserRole>At least 800*800 px recommended</UserRole>
                </UserDetails>
            </UserContainer>
            <Box
                sx={{
                    p: 2,
                    border: '1px solid #A1A1AA',
                    borderRadius: '8px',
                    marginTop: '24px'
                }}
            >
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography sx={{ color: '#52525B', fontSize: 20, fontWeight: 500 }}>Profile</Typography>
                    <FormBtn type="submit" sx={{ width: 'auto', padding: '8px 24px' }} disabled={profileEditStatus === POST_STATUS.POSTING}>
                        Save
                    </FormBtn>
                </Box>
                {profileEditError && (
                    <Box mt={2}>
                        <Alert severity="error">{profileEditError}</Alert>
                    </Box>
                )}

                <Box p={2}>
                    <Box>
                        <Alert severity="info"> Please click on save if you made any changes</Alert>
                    </Box>
                    {hasErrors && (
                        <Box mt={2}>
                            <Alert severity="error">
                                Please fill all <RequiredMark /> required fields
                            </Alert>
                        </Box>
                    )}
                    <Grid container mt={2}>
                        <Controller
                            name="about_me"
                            control={control}
                            rules={{
                                minLength: {
                                    value: 50,
                                    message: 'About me must be between 50-750 characters long'
                                },
                                maxLength: {
                                    value: 750,
                                    message: 'About me must be between 50-750 characters long'
                                }
                            }}
                            render={({ field }) => (
                                <>
                                    <Label error={errors.about_me}>About me</Label>
                                    <StyledTextArea
                                        id="about_me"
                                        value={formData.about_me}
                                        name="about_me"
                                        rows="5"
                                        placeholder="Share a little about yourself, your interests, or professional background"
                                        required={false}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('about_me', e.target.value);
                                        }}
                                    />
                                    <StyledHelperText error={errors.about_me}>{errors?.about_me?.message}</StyledHelperText>
                                </>
                            )}
                        />
                    </Grid>
                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="first_name"
                                control={control}
                                rules={{
                                    required: 'First name is required.',
                                    minLength: {
                                        value: 3,
                                        message: 'First name must be between 3-25 characters long'
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: 'First name must be between 3-25 characters long'
                                    },
                                    validate: (value) => {
                                        if (value && value.trim().length < 3) {
                                            return 'First name must be between 3-75 characters long';
                                        }
                                        if (!/^[a-zA-Z]+$/.test(value.trim())) {
                                            return 'First name should contain only letters.';
                                        }
                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInput
                                        label="First name"
                                        id="first_name"
                                        type="text"
                                        value={formData.first_name}
                                        placeholder="Enter your first name"
                                        required
                                        {...field}
                                        error={!!errors.first_name}
                                        helperText={errors.first_name ? errors.first_name.message : ''}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('first_name', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="last_name"
                                control={control}
                                rules={{
                                    required: 'Last name is required.',
                                    minLength: {
                                        value: 3,
                                        message: 'Last name must be at least 3 characters long'
                                    },
                                    maxLength: {
                                        value: 25,
                                        message: 'Last name must be between 3-25 characters long'
                                    },
                                    validate: (value) => {
                                        if (value && value.trim().length < 3) {
                                            return 'Last name must be between 3-25 characters long';
                                        }
                                        if (!/^[a-zA-Z]+$/.test(value.trim())) {
                                            return 'Last name should contain only letters.';
                                        }
                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInput
                                        label="Last name"
                                        id="last_name"
                                        value={formData.last_name}
                                        type="text"
                                        placeholder="Enter your last name"
                                        required
                                        {...field}
                                        error={!!errors.last_name}
                                        helperText={errors.last_name ? errors.last_name.message : ''}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('last_name', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="country"
                                control={control}
                                rules={{
                                    required: 'Country is required.'
                                }}
                                render={({ field }) => (
                                    <CSCSelectInput
                                        label="Country"
                                        id="country"
                                        name="country"
                                        options={countries.map((country) => ({
                                            value: country.id,
                                            label: country.name
                                        }))}
                                        fullWidth={false}
                                        required
                                        error={!!errors.country}
                                        helperText={errors.country ? errors.country.message : ''}
                                        value={formData.country_id}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleCountryChange(e);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <CSCSelectInput
                                        label="State"
                                        id="state"
                                        name="state"
                                        disabled={!formData.country_id} // Disable until country is selected
                                        options={states.map((state) => ({
                                            value: state.id,
                                            label: state.name
                                        }))}
                                        fullWidth={false}
                                        error={!!errors.state}
                                        helperText={errors.state ? errors.state.message : ''}
                                        value={formData.state_id}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleStateChange(e);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="city"
                                control={control}
                                render={({ field }) => (
                                    <CSCSelectInput
                                        label="City"
                                        id="city"
                                        name="city"
                                        disabled={!formData.state_id} // Disable until state is selected
                                        options={cities.map((city) => ({
                                            value: city.id,
                                            label: city.name
                                        }))}
                                        fullWidth={false}
                                        error={!!errors.city}
                                        helperText={errors.city ? errors.city.message : ''}
                                        value={formData.city_id}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleCityChange(e);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="referred_by"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        const trimmedValue = value.trim();
                                        if (trimmedValue.length === 0) {
                                            return true; // Allow empty input if that is acceptable
                                        }
                                        if (trimmedValue.length < 2) {
                                            return 'Referred by must be between 2-25 characters long';
                                        }
                                        if (trimmedValue.length > 25) {
                                            return 'Referred by must be between 2-25 characters long';
                                        }
                                        if (!/^[a-zA-Z\s]+$/.test(trimmedValue)) {
                                            return 'Referred by should contain only letters.';
                                        }
                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInput
                                        label="Referred by"
                                        id="referred_by"
                                        value={formData.referred_by}
                                        type="text"
                                        required={false}
                                        fullWidth={false}
                                        error={!!errors.referred_by}
                                        helperText={errors.referred_by ? errors.referred_by.message : ''}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('referred_by', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="platform"
                                control={control}
                                render={({ field }) => (
                                    <StyledRadioGroup
                                        {...field}
                                        value={formData.platform}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('platform', e.target.value);
                                        }}
                                    >
                                        <Box sx={{ flex: 1, padding: 0 }}>
                                            <FormControlLabel value="LinkedIn" control={<Radio sx={{ py: 0, marginLeft: '4px' }} />} label={<StyledRadioLabel>LinkedIn</StyledRadioLabel>} />
                                        </Box>
                                        <StyledRadioBox>
                                            <FormControlLabel value="Xing" control={<Radio sx={{ py: 0 }} />} label={<StyledRadioLabel>Xing</StyledRadioLabel>} />
                                        </StyledRadioBox>
                                    </StyledRadioGroup>
                                )}
                            />
                            <Controller
                                name="platform_url"
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^(ftp|http|https):\/\/[^ "]+$/,
                                        message: 'Please enter a valid url.'
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInput
                                        id="platform_url"
                                        type="url"
                                        value={formData.platform_url}
                                        placeholder={`e.g. https://in.linkedin.com/in/tanmay-shimpi-3976a116b`}
                                        containerStyle={{ marginTop: '0px' }}
                                        inputStyle={{
                                            '&:hover fieldset': {
                                                border: '1px solid #6a1b9a'
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '1px solid #6a1b9a'
                                            }
                                        }}
                                        required
                                        {...field}
                                        error={!!errors.platform_url}
                                        helperText={errors.platform_url ? errors.platform_url.message : ''}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('platform_url', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="employment_type"
                                control={control}
                                rules={{
                                    required: 'Employment type is required.'
                                }}
                                render={({ field }) => (
                                    <SelectInput
                                        label="Employment type"
                                        id="employment_type"
                                        name="employment_type"
                                        options={EMPLOYMENT_TYPE_OPTIONS}
                                        fullWidth={false}
                                        required
                                        error={!!errors.employment_type}
                                        helperText={errors.employment_type ? errors.employment_type.message : ''}
                                        value={formData.employment_type}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('employment_type', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="employment_status"
                                control={control}
                                rules={{
                                    required: 'Employment status is required.'
                                }}
                                render={({ field }) => (
                                    <SelectInput
                                        label="Employment status"
                                        id="employment_status"
                                        name="employment_status"
                                        options={EMPLOYMENT_STATUS_OPTIONS}
                                        fullWidth={false}
                                        required
                                        error={!!errors.employment_status}
                                        helperText={errors.employment_status ? errors.employment_status.message : ''}
                                        value={formData.employment_status}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('employment_status', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    {(formData?.employment_status === EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED || formData?.employment_status === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                        <Grid container spacing={isMobile ? 0 : 1}>
                            <Grid item xs={12} sm={12} md={6}>
                                {' '}
                                <Controller
                                    name="current_job_title"
                                    control={control}
                                    rules={{
                                        required: 'Current job title is required.',
                                        minLength: {
                                            value: 3,
                                            message: 'Current job title must be between 3-75 characters long'
                                        },
                                        maxLength: {
                                            value: 75,
                                            message: 'Current job title must be between 3-75 characters long'
                                        },
                                        validate: (value) => {
                                            if (value.trim().length < 3) {
                                                return 'Current job title must be between 3-75 characters long';
                                            }
                                            return true;
                                        }
                                    }}
                                    render={({ field }) => (
                                        <FormInput
                                            label=" Current job title"
                                            id="current_job_title"
                                            value={formData.current_job_title}
                                            type="text"
                                            placeholder="Developer, Manager, Analyst, Consultant"
                                            required
                                            {...field}
                                            error={!!errors.current_job_title}
                                            helperText={errors.current_job_title ? errors.current_job_title.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange('current_job_title', e.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Controller
                                    name="current_company"
                                    control={control}
                                    rules={{
                                        required: 'Current company is required.',
                                        minLength: {
                                            value: 3,
                                            message: 'Current company must be between 3-75 characters long'
                                        },
                                        maxLength: {
                                            value: 75,
                                            message: 'Current company must be between 3-75 characters long'
                                        },
                                        validate: (value) => {
                                            if (value && value.trim().length < 3) {
                                                return 'Current company must be between 3-75 characters long';
                                            }
                                            return true;
                                        }
                                    }}
                                    render={({ field }) => (
                                        <FormInput
                                            label=" Current company"
                                            id="current_company"
                                            value={formData.current_company}
                                            type="text"
                                            placeholder="SAP, Siemens, BMW, Self-employed"
                                            required
                                            {...field}
                                            error={!!errors.current_company}
                                            helperText={errors.current_company ? errors.current_company.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange('current_company', e.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {(formData?.employment_status === EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB || formData?.employment_status === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                        <Grid container spacing={isMobile ? 0 : 1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Controller
                                    name="preferred_position"
                                    control={control}
                                    rules={{
                                        required: 'Preferred position is required.',
                                        minLength: {
                                            value: 3,
                                            message: 'Preferred position must be between 3-75 characters long'
                                        },
                                        maxLength: {
                                            value: 75,
                                            message: 'Preferred position must be between 3-75 characters long'
                                        },
                                        validate: (value) => {
                                            if (value && value.trim().length < 3) {
                                                return 'Preferred position must be between 3-75 characters long';
                                            }
                                            return true;
                                        }
                                    }}
                                    render={({ field }) => (
                                        <FormInput
                                            label="Preferred position"
                                            id="preferred_position"
                                            value={formData.preferred_position}
                                            type="text"
                                            placeholder="Developer, Manager, Analyst, Consultant"
                                            required
                                            {...field}
                                            error={!!errors.preferred_position}
                                            helperText={errors.preferred_position ? errors.preferred_position.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange('preferred_position', e.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <Controller
                                        name="expected_salary"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectInput
                                                label="Expected salary"
                                                id="expected_salary"
                                                name="expected_salary"
                                                options={SALARY_RANGE_OPTIONS}
                                                fullWidth={false}
                                                required={false}
                                                error={!!errors.expected_salary}
                                                helperText={errors.expected_salary ? errors.expected_salary.message : ''}
                                                value={formData.expected_salary}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleInputChange('expected_salary', e.target.value);
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    {(formData?.employment_status === EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED || formData?.employment_status === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                        <Grid container spacing={isMobile ? 0 : 1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <Controller
                                        name="current_salary"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectInput
                                                label="Current salary"
                                                id="current_salary"
                                                name="current_salary"
                                                options={SALARY_RANGE_OPTIONS}
                                                fullWidth={false}
                                                required={false}
                                                error={!!errors.current_salary}
                                                helperText={errors.current_salary ? errors.current_salary.message : ''}
                                                value={formData.current_salary}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleInputChange('current_salary', e.target.value);
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={isMobile ? 0 : 1}>
                        {/* Resume Drive Link Field */}
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="resume"
                                control={control}
                                rules={{
                                    pattern: {
                                        value: /^(ftp|http|https):\/\/[^ "]+$/,
                                        message: 'Please enter a valid URL for the resume link.'
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: 'Resume link must not exceed 200 characters.'
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInput
                                        label="CV/Resume drive link"
                                        id="resume"
                                        name="resume"
                                        placeholder="Enter cv/resume drive link"
                                        value={formData.resume}
                                        type="text"
                                        fullWidth
                                        {...field}
                                        error={!!errors.resume}
                                        helperText={errors.resume ? errors.resume.message : ''}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('resume', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Cover Letter Drive Link Field */}
                        <Grid item xs={12} sm={12} md={6}>
                            <Controller
                                name="cover_letter"
                                control={control}
                                rules={{
                                    // Optional field, so no `required` rule here
                                    pattern: {
                                        value: /^(ftp|http|https):\/\/[^ "]+$/,
                                        message: 'Please enter a valid URL for the cover letter link.'
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: 'Cover letter link must not exceed 200 characters.'
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInput
                                        label="Cover letter drive Link"
                                        id="cover_letter"
                                        name="cover_letter"
                                        placeholder="Enter cover letter drive link"
                                        value={formData.cover_letter}
                                        type="text"
                                        fullWidth
                                        {...field}
                                        error={!!errors.cover_letter}
                                        helperText={errors.cover_letter ? errors.cover_letter.message : ''}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleInputChange('cover_letter', e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ mt: 0.7 }}>
                                <Label>Domain</Label>
                                <Box>
                                    {!isNullOrEmpty(formData.domains) ? (
                                        <TagContainer>
                                            {formData.domains.map((domain) => (
                                                <SelectedButton key={domain} variant="contained" onClick={() => handleEdit('domain')}>
                                                    {truncateText(domain, textLimit)} <CloseIcon sx={{ ml: 1 }} />
                                                </SelectedButton>
                                            ))}
                                        </TagContainer>
                                    ) : (
                                        <AddButton variant="contained" onClick={() => handleSlideUpOpen('domain')} startIcon={<AddOutlinedIcon />}>
                                            Add domain
                                        </AddButton>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ mt: 1 }}>
                                <Label>Past companies</Label>
                                <Box>
                                    {!isNullOrEmpty(formData.past_companies) ? (
                                        <TagContainer>
                                            {formData.past_companies.map((company) => (
                                                <SelectedButton key={company} variant="contained" onClick={() => handleEdit('companies')}>
                                                    {truncateText(company, textLimit)} <CloseIcon sx={{ ml: 1 }} />
                                                </SelectedButton>
                                            ))}
                                        </TagContainer>
                                    ) : (
                                        <AddButton variant="contained" onClick={() => handleSlideUpOpen('companies')} startIcon={<AddOutlinedIcon />}>
                                            Add company
                                        </AddButton>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={isMobile ? 0 : 1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ mt: 0.7 }}>
                                <Label>
                                    Recent work experience
                                    {isValidWorkExperience(formData.work_experience) && (
                                        <IconButton
                                            onClick={() => handleOpenWorkExperienceDialog()}
                                            sx={{ marginLeft: '10px', cursor: 'pointer' }} // Adjust this for spacing
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Label>

                                <Box>
                                    {isValidWorkExperience(formData.work_experience) ? (
                                        <DetailList>
                                            <Interval>
                                                <b>Company</b> : {formData.work_experience.company_name}
                                            </Interval>
                                            <Interval>
                                                <b>Role</b> :{formData.work_experience.role}
                                            </Interval>
                                            <Interval>
                                                <b>Interval</b> : {formatInterval(formData.work_experience.from, formData.work_experience.to)}
                                            </Interval>
                                        </DetailList>
                                    ) : (
                                        <AddButton variant="contained" onClick={() => handleOpenWorkExperienceDialog()} startIcon={<AddOutlinedIcon />}>
                                            Add Recent Work Experience
                                        </AddButton>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ mt: 1.5 }}>
                                <Label>
                                    Highest level of qualification
                                    {isValidEducation(formData.education) && (
                                        <IconButton
                                            onClick={() => handleOpenEducationDialog()}
                                            sx={{ marginLeft: '10px', cursor: 'pointer' }} // Adjust this for spacing
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Label>

                                <Box>
                                    {isValidEducation(formData.education) ? (
                                        <DetailList>
                                            <Interval>
                                                <b>Company</b> : {formData.education.institute_name}
                                            </Interval>
                                            <Interval>
                                                <b>Role</b> :{formData.education.course}
                                            </Interval>
                                            <Interval>
                                                <b>Interval</b> : {formatInterval(formData.education.from, formData.education.to)}
                                            </Interval>
                                        </DetailList>
                                    ) : (
                                        <AddButton sx onClick={() => handleOpenEducationDialog()} startIcon={<AddOutlinedIcon />}>
                                            Add Education
                                        </AddButton>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SlideUp open={slideUpOpen} onClose={handleSlideUpClose} content={slideUpContent} page="profile" />

            <WorkExperienceDialog openDialog={openWorkExperieceDialog} handleClose={handleCloseWorkExperienceDialog} />
            <EducationDialog openDialog={openEducationDialog} handleClose={handleCloseEducationDialog} />

            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled" sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <ComingSoonDialog openDialog={openComingSoonDialog} handleClose={handleCloseComingSoonDialog} />
        </Container>
    );
};

const AvatarContainer = styled(Box)`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    z-index: 0;
`;

const UserContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
`;

const UserRole = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.24px;
    word-break: break-word;
`;

const UserDetails = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const StyledRadioLabel = styled('span')`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
`;


const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
`;

const StyledRadioBox = styled(Box)`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0;
`;

const SelectedButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    text-transform: none;
    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.dark};
    }
`;

const StyledWelcomeText = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: #27272a;
    text-transform: capitalize;
`;

const Interval = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[600]};
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    word-break: break-word;
`;

const DetailList = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 4px;
    margin-top: 4px;
`;

export default EditProfile;
