import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EMPLOYMENT_STATUS, EMPLOYMENT_TYPE, EVENT_MODE, EVENT_STATUS, EVENT_TYPE, JOB_POST_STATUS, SALARY_STATUS } from '../constants/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

// Utility function to format event date and time
export const formatEventDateTime = (startDate, endDate) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (!start.isValid() || !end.isValid()) {
        return '--';
    }
    const startMonth = start.format('MMM'); // Short month format
    const startDay = start.format('D');
    const startTime = start.format('HH:mm');

    const endMonth = end.format('MMM'); // Short month format
    const endDay = end.format('D');
    const endTime = end.format('HH:mm');

    const startYear = start.format('YYYY');
    const endYear = end.format('YYYY');

    // If start and end are in the same day
    if (start.isSame(end, 'day')) {
        if (start.isSame(new Date(), 'year')) {
            return `${startMonth} ${startDay}, ${startTime} - ${endTime}`;
        } else {
            return `${startMonth} ${startDay}, ${startYear}, ${startTime} - ${endTime}`;
        }
    } else {
        if (start.isSame(new Date(), 'year') && end.isSame(new Date(), 'year')) {
            return `${startMonth} ${startDay}, ${startTime} - ${endMonth} ${endDay}, ${endTime}`;
        } else {
            return `${startMonth} ${startDay}, ${startYear}, ${startTime} - ${endMonth} ${endDay}, ${endYear}, ${endTime}`;
        }
    }
};

export function timeAgo(createdAt) {
    const createdTime = new Date(createdAt);

    if (isNaN(createdTime.getTime())) {
        return '';
    }

    // Get the current time
    const currentTime = new Date();

    const diffInMs = currentTime - createdTime;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays >= 1) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    } else if (diffInHours >= 1) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else if (diffInMinutes >= 1) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    } else {
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    }
}

export const truncateText = (text, limit) => {
    if (text.length <= limit) {
        return text;
    }
    return text.slice(0, limit) + '...';
};

export const getStatusText = (status) => {
    const statusMap = {
        [JOB_POST_STATUS.IN_REVIEW]: 'In review',
        [JOB_POST_STATUS.ACTIVE]: 'Active',
        [JOB_POST_STATUS.REJECTED]: 'Rejected',
        [JOB_POST_STATUS.CLOSED]: 'Closed'
    };

    return statusMap[status] || '--';
};

export const EMPLOYMENT_STATUS_OPTIONS = [
    { value: 'CURRENTLY_EMPLOYED', label: 'Currently employed & referral advocate' },
    { value: 'ACTIVELY_LOOKING_FOR_JOB', label: 'Actively looking for jobs' },
    { value: 'EMPLOYED_AND_SEEKING_JOB', label: 'Employed & seeking job' }
];

export const getEmploymentStatus = (employmentStatus) => {
    const statusMap = {
        [EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB]: 'Actively looking for jobs',
        [EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED]: 'Currently employed',
        [EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB]: 'Employed & seeking job'
    };

    return statusMap[employmentStatus] || '--';
};

export const getEmploymentType = (employmentType) => {
    const typeMap = {
        [EMPLOYMENT_TYPE.FULL_TIME]: 'Full time',
        [EMPLOYMENT_TYPE.PART_TIME]: 'Part time',
        [EMPLOYMENT_TYPE.INTERNSHIP]: 'Internship',
    };

    return typeMap[employmentType] || '--';
};

export function waitForSeconds(seconds) {
    return new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
}

export const formatDate = (date) => {
    return dayjs(date).format('DD.MM.YYYY');
};

export const formatTime = (date) => {
    return dayjs(date).format('hh:mm A');
};

export const formatInterval = (from, to) => {
    // Check if 'from' is a valid date
    const fromDate = dayjs(from);
    if (!fromDate.isValid()) return '--'; // Return '--' if 'from' is not a valid date

    // Format the 'from' date in the desired format (15 Nov 2023)
    const formattedFrom = fromDate.format('DD MMM YYYY');

    // If 'to' is null or empty string, return formattedFrom - present
    if (!to) {
        return `${formattedFrom} - present`;
    }

    // Check if 'to' is a valid date
    const toDate = dayjs(to);
    if (!toDate.isValid()) return '--'; // Return '--' if 'to' is not a valid date

    // Format the 'to' date in the desired format (15 Nov 2023)
    const formattedTo = toDate.format('DD MMM YYYY');

    // Return formatted interval: 'from - to'
    return `${formattedFrom} - ${formattedTo}`;
};

export const getEventType = (eventType) => {
    const typeMap = {
        [EVENT_TYPE.FREE]: 'Free',
        [EVENT_TYPE.PAID]: 'Paid'
    };

    return typeMap[eventType] || '--';
};

export const getEventMode = (eventMode) => {
    const modeMap = {
        [EVENT_MODE.OFFLINE]: 'Offline',
        [EVENT_MODE.ONLINE]: 'Online'
    };

    return modeMap[eventMode] || '--';
};

export const getEventStatus = (eventStatus) => {
    const statusMap = {
        [EVENT_STATUS.IN_REVIEW]: 'In review',
        [EVENT_STATUS.ACCEPTED]: 'Accepted',
        [EVENT_STATUS.REJECTED]: 'Rejected',
        [EVENT_STATUS.ON_HOLD]: 'On hold'
    };

    return statusMap[eventStatus] || '--';
};

export const getSalaryRange = (salary) => {
    const salaryMap = {
        [SALARY_STATUS.SALARY_50K_TO_75K]: '50K to 75K',
        [SALARY_STATUS.SALARY_75K_TO_100K]: '75K to 100K',
        [SALARY_STATUS.SALARY_100K_TO_125K]: '100K to 125K',
        [SALARY_STATUS.SALARY_125K_TO_150K]: '125K to 150K',
        [SALARY_STATUS.SALARY_150K_PLUS]: '150K +'

    };

    return salaryMap[salary] || '--';
};


export const isNullOrEmpty = (value) => {
    // Return true for null or undefined values
    if (value == null) return true;

    // Check for empty string after trimming
    if (typeof value === 'string') return value.trim() === '';

    // Check for empty array
    if (Array.isArray(value)) return value.length === 0;

    // Check for empty object (no own properties)
    if (value instanceof Object) return Object.keys(value).length === 0;

    // Check for empty Map or Set
    if (value instanceof Map || value instanceof Set) return value.size === 0;

    // For any other types (like numbers, booleans), consider it not empty
    return false;
};

export const isValidWorkExperience = (experience) => {
    return !isNullOrEmpty(experience.company_name) && !isNullOrEmpty(experience.role) && !isNullOrEmpty(experience.from);
};

export const isValidEducation = (education) => {
    return !isNullOrEmpty(education.institute_name) && !isNullOrEmpty(education.course) && !isNullOrEmpty(education.from);
};


export const formatText =(input) => {
    // Check for null, undefined, empty string, or non-string input
    if (!input || typeof input !== 'string')
    {
        return '--';
    }

    // Trim the input to remove extra spaces and format
    const trimmedInput = input.trim();
    if (trimmedInput === '')
    {
        return '--';
    }

    // Capitalize the first character and make the rest lowercase
    return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1).toLowerCase();
}
