import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import FlakyIcon from '@mui/icons-material/Flaky';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

export const navItems = [
    { key: 'home', title: 'Home', href: '/', icon: HomeOutlinedIcon },
    { key: 'jobs', title: 'Jobs', href: '/jobs', icon: WorkOutlineOutlinedIcon },
    { key: 'events', title: 'Events', href: '/events', icon: EventOutlinedIcon }
];

export const bottomNavItems = [
    { key: 'aboutus', title: 'About us', href: '/about-us' },
    // { key: 'faqs', title: 'FAQs', href: '/faqs' },
    { key: 'promotions', title: 'Promotions', href: '/promotions' },
    // { key: 'testimonials', title: 'Testimonials', href: '/testimonial' },
    // { key: 'feedback', title: 'Feedback', href: '/feedback' },
    { key: 'terms', title: 'Terms & Conditions', href: '/terms-and-conditions' },
    { key: 'privacy', title: 'Privacy', href: '/privacy-policy' }
];

export const adminNavItems = [
    { key: 'home', title: 'Home', href: '/', icon: HomeOutlinedIcon },
    { key: 'manage-requests', title: 'Manage requests', href: '/admin/manage-requests', icon: FlakyIcon },
    {
        key: 'people-management',
        title: 'People management',
        href: '/admin/people-management',
        icon: ManageAccountsIcon
    },
    // { key: 'monitoring-job-posts', title: 'Monitoring job post', href: "/admin/monitoring-job-posts", icon: EventOutlinedIcon },
    { key: 'events', title: 'Events', href: '/admin/events', icon: EventOutlinedIcon }
];
