import styled from '@emotion/styled';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

export const ComingSoonDialog = ({ openDialog, handleClose }) => {
    return (
        <Dialog maxWidth="xs" fullWidth open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <CardContainer>
                <DialogTitle>
                    <DialogTitleWrapper>
                        <StyledDialogTitle>Coming Soon!</StyledDialogTitle>
                        <CloseOutlinedIcon sx={{ color: '#333333', cursor: 'pointer' }} onClick={handleClose} />
                    </DialogTitleWrapper>
                </DialogTitle>
                <DialogContent>
                    <StyledDialogSubTitle>We're working on something amazing. This feature will be available soon. Stay tuned for updates, and thank you for your patience!</StyledDialogSubTitle>
                </DialogContent>
            </CardContainer>
        </Dialog>
    );
};

const StyledDialogTitle = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 20px;
    font-weight: 500;
`;

const StyledDialogSubTitle = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[600]};
    font-size: 14px;
    font-weight: 500;
`;

const CardContainer = styled(Box)`
    padding: 16px;
    background: white;
    border-radius: 8px;
`;

const DialogTitleWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
