import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { truncateText } from '../../utils/helpers';
import AvatarPlaceholder from '../Common/AvatarPlaceholder';
import { NavItem } from './NavItem';
import { bottomNavItems, navItems } from './config';
import UserEmail from '../Common/UserEmail';

export function MobileNav({ open, onClose }) {
    const pathname = window.location.pathname;
    const { firstName, lastName, email } = useSelector((state) => state.user);
    return (
        <StyledDrawer onClose={onClose} open={open}>
            <Stack spacing={2} sx={{ p: 2 }}>
                <StyledUserBox>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <AvatarPlaceholder firstName={firstName} lastName={lastName} size="40px" fontSize="16px" />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledUserName>{truncateText(`${firstName} ${lastName}`, 18)}</StyledUserName>
                            <UserEmail>{truncateText(email, 25)}</UserEmail>
                        </Box>
                    </Box>
                </StyledUserBox>
            </Stack>
            <Box component="nav" sx={{ flex: '1 1 auto', p: '12px' }}>
                {renderNavItems({ pathname, items: navItems, onClose })}
            </Box>

            <Box component="nav" sx={{ flex: '0 1 auto', p: '12px' }}>
                {renderbottomItems({ pathname, items: bottomNavItems, onClose })}
            </Box>
        </StyledDrawer>
    );
}

function renderNavItems({ items = [], pathname, onClose }) {
    const children = items.reduce((acc, curr) => {
        const { key, ...item } = curr;

        acc.push(<NavItem key={key} pathname={pathname} onClick={onClose} {...item} />);

        return acc;
    }, []);

    return (
        <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
            {children}
        </Stack>
    );
}

function renderbottomItems({ items = [], pathname, onClose }) {
    const children = items.reduce((acc, curr) => {
        const { key, ...item } = curr;

        acc.push(<NavItem key={key} pathname={pathname} onClick={onClose} {...item} />);

        return acc;
    }, []);

    return (
        <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
            {children}
        </Stack>
    );
}

const StyledDrawer = styled(Drawer)`
    --MobileNav-background: '#fff';
    --MobileNav-color: var(--mui-palette-common-white);
    --NavItem-color: #000;
    --NavItem-hover-background: rgba(255, 255, 255, 0.04);
    --NavItem-active-background: #400091;
    --NavItem-active-color: #fff;
    --NavItem-disabled-color: gray;
    --NavItem-icon-color: #000;
    --NavItem-icon-active-color: #ffffff;
    --NavItem-icon-disabled-color: gray;
    background-color: var(--MobileNav-background);
    color: var(--MobileNav-color);
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    width: 320px;
    max-width: calc(65vw);
    z-index: 1100;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const StyledUserBox = styled(Box)`
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    padding: 4px;
`;

const StyledUserName = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 20px;
    font-weight: 600;
    word-break: break-word;
`;


