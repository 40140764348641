import { UserClient } from '../config';

export const getUserProfile = async (userId) => {
    const response = await UserClient.get(`/profile/${userId}`);
    return response;
};

export const updateUserProfile = async (userId, data) => {
    const response = await UserClient.put(`/profile/${userId}`, data);
    return response;
};

export const verifyUserToken = async () => {
    const response = await UserClient.get(`/verify-token`);
    return response;
};

export const getAllSearchResults = async (q) => {
    const response = await UserClient.get('/search', {
        params: { q }
    });
    return response;
};

export const getUserPublicProfile = async (userId) => {
    const response = await UserClient.get(`/profile/public/${userId}`);
    return response;
};

export const getUserProfileStatus = async (userId) => {
    const response = await UserClient.get(`/profile/status/${userId}`);
    return response;
};
