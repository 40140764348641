import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';


const UserEmail = styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-weight: 400;
    word-break: break-word;
    text-transform: lowercase;

    ${(props) =>
        props.sx &&
        css`
            ${props.sx}
        `}

    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-right: 8px;
    }
`;

export default UserEmail;

