import React from 'react';
import styled from '@emotion/styled';

// Styled component for the Avatar Container (circle)
const StyledAvatarPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: ${(props) => props.size || '50px'};
    height: ${(props) => props.size || '50px'};
    background-color: ${(props) => props.bgColor};
    color: white;
    font-size: ${(props) => props.fontSize || '18px'};
    font-weight: bold;
    text-transform: uppercase;
`;

const getInitials = (firstName, lastName) => {
    // If either firstName or lastName is empty or undefined, use "U" for User
    if (!firstName && !lastName) {
        return 'U';
    }

    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';

    // If both names are missing, it will return 'U'
    return firstInitial + lastInitial || 'U';
};

const getBackgroundColor = (initials) => {
    const colors = [
        '#FF5733', // Red
        '#33FF57', // Green
        '#3357FF', // Blue
        '#FF33A8', // Pink
        '#FF8C33', // Orange
        '#4A7E8B', // Cyan
        '#964B00', // Yellow
        '#7C33FF' // Purple
    ];
    const letterCode = initials.charCodeAt(0); // Use the first letter's char code
    const colorIndex = (letterCode - 65) % colors.length; // Map A-Z to the color set
    return colors[colorIndex];
};

const AvatarPlaceholder = ({ firstName, lastName, size, fontSize }) => {
    const initials = getInitials(firstName, lastName);
    const bgColor = getBackgroundColor(initials);

    return (
        <StyledAvatarPlaceholder size={size} fontSize={fontSize} bgColor={bgColor}>
            {initials}
        </StyledAvatarPlaceholder>
    );
};

export default AvatarPlaceholder;
